import banner from "../imagenes/img-banner-2.webp";
import registroBanner from "../imagenes/registro-banner.webp";
import ennvioBanner from "../imagenes/envia-banner.webp";
import desembolsoBanner from "../imagenes/desembolso-banner.webp";
import liquidezBanner from "../imagenes/liquidez.webp";
import evaluacionBanner from "../imagenes/evaluacion.webp";
import reduccionBanner from "../imagenes/reduccion.webp";
import financiamientoBanner from "../imagenes/financiamiento.webp";
import formulario from "../imagenes/formulario.webp";
import apefac from "../imagenes/apefac.webp";
import cavali from "../imagenes/cavali.webp";
import produce from "../imagenes/produce.webp";
import superintendencia from "../imagenes/superintendencia.webp";
import Formulario from "../Formulario/Formulario";
import "bootstrap/dist/css/bootstrap.min.css";
import YouTube from "react-youtube";
import { useParams } from "react-router-dom";

export default function Home() {

  const videoId = "Wmqa7V1rV30";
  const videoId2 = "5ztU9fcNfpg";
  
  const {hk} = useParams();



  const opts = {
    height:   window.innerWidth < 600 ? "200" : "400",
    width:  window.innerWidth < 600 ? "300" : "600",
    playerVars: {
      autoplay: 0,
    },
  };


  const scrollToSection = () => {
    const section = document.getElementById("formulario");
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="home">
      <section className="cobra-hoy">
          <h3 className="cobra-hoy-frase">
            ¡Solicita tu adelanto <span>AHORA!</span>
          </h3>
          <img src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2019@2x-1.png" alt="proceso-factoring" className="proceso-imagen"/>
      </section>

      <Formulario hk={hk}/>
      
      {/* <section>
      <div className="cuanto-recibes-box">
          <h6 className="palabra-cuanto">
            ¿Cuanto <span className="palabra-recibes"> recibes?</span>{" "}
          </h6>

          <div className="formulario-container">
            <img src={formulario} alt="banner" className="formulario" />
          </div>
        </div>
      </section>

      <section className="descripcion">
        <div className="como-funciona-box">
          <h6 className="palabra-beneficios">
            Beneficios del Factoring de{" "}
            <span className="palabra-genera"> Genera</span>{" "}
          </h6>
        </div>

        <div className="proceso">
          <div className="registro">
            <div>
              <img
                src={liquidezBanner}
                alt="registro"
                className="banner-proceso"
              />
            </div>

            <h5 className="registrate-palabra">
              Liquidez inmediata para tu negocio
            </h5>
            <p className="descripcion-procesos">
              Obtén dinero para pagar <br /> impuestos, proveedores, planilla o
              lo que tu negocio necesite.
            </p>
          </div>

          <div className="envio">
            <div>
              <img
                src={evaluacionBanner}
                alt="registro"
                className="banner-proceso"
              />
            </div>

            <h5 className="envio-palabra">Evaluamos a tu cliente, no a ti</h5>
            <p className="descripcion-procesos">
              La evaluación se centra en tu cliente, no en ti. Por ello puedes
              apoyarte en la solidez crediticia de tu comprador.
            </p>
          </div>

          <div className="desembolso">
            <div>
              <img
                src={reduccionBanner}
                alt="registro"
                className="banner-proceso"
              />
            </div>

            <h5 className="desembolso-palabra">Reduce esfuerzos de cobranza</h5>
            <p className="descripcion-procesos">
            Olvidate de la cobranza: Nos encargamos de cobrar tus facturas.
            </p>
            <br />
            
          </div>

          <div className="desembolso">
            <div>
              <img
                src={financiamientoBanner}
                alt="registro"
                className="banner-proceso"
              />
            </div>

            <h5 className="desembolso-palabra">Financiamiento 100% digital</h5>
            <p className="descripcion-procesos">
              Olvídate de la pérdida de tiempo y trámites engorrosos, te
              ofrecemos un proceso rápido y digital.
            </p>
          </div>
        </div>
      </section>

      <section className="video" style={{backgroundColor: "#003262"}}>
        <div className="video-container">
          <h5 className="video-text">Conoce el testimonio de otras personas:</h5>
          <div className="videos">
          <div className="video-cuadro">
            <YouTube
              videoId={videoId}
              opts={opts}
              className="react-youtube"
            />
          </div>
          <div className="video-cuadro">
            <YouTube
              videoId={videoId2}
              opts={opts}
              className="react-youtube"
            />
          </div>
          </div>
         
        </div>
      </section> 

      <section
        className="banner"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white"
        }}>
        <div
          className="banner-registros">
          <h6
            className="estamos-palabra"
            style={{
              color: "#003262",
              fontWeight: "500",
            }}>
            Estamos <span></span>
            <span className="registrados-palabra" style={{ color: "#ff6300" }}>
              registrados en:
            </span>
          </h6>
          <div className="imagenes-container-banner">
            <img
              src={superintendencia}
              alt="banner"
              className="banner-imagenes"
            />
            <img
              src={produce}
              alt="banner"
              className="banner-imagenes"
            />
            <img
              src={cavali}
              alt="banner"
              className="banner-imagenes"
            />
            <img
              src={apefac}
              alt="banner"
              className="banner-imagenes"
            />
          </div>
        </div>
      </section> */}

    </div>
  );
}
