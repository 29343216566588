import envioExistoBanner from "../imagenes/envio_exitoso_banner.png";
import relleno from "../imagenes/relleno.png";
import liquidezBanner from "../imagenes/liquidez.webp";
import evaluacionBanner from "../imagenes/evaluacion.webp";
import reduccionBanner from "../imagenes/reduccion.webp";
import financiamientoBanner from "../imagenes/financiamiento.webp";
import apefac from "../imagenes/apefac.webp";
import cavali from "../imagenes/cavali.webp";
import produce from "../imagenes/produce.webp";
import superintendencia from "../imagenes/superintendencia.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";

export default function GraciasPagina() {
  const videoId = "Wmqa7V1rV30";
  const videoId2 = "5ztU9fcNfpg";
  const opts = {
    height: "400",
    width: "700",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "6rem",
          backgroundColor: "#ff6300",
          color: "white",
          alignItems: "center",
          height: "25rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "20rem", height: "20rem", position: "relative" }}>
            <img
              src={envioExistoBanner}
              alt="banner"
              style={{
                width: "25rem",
                backgroundSize: "contain",
                backgroundPosition: "center",
                position: "absolute",
                marginTop: "0.6rem",
                marginLeft: "0rem",
              }}
            />
          </div>
        </div>
        <div
          className="cobra-hoy-box-1"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img src={relleno} alt="" style={{ width: "6rem" }} />
          <h3
            style={{
              fontSize: "2.5rem",
              backgroundColor: "orange",
              padding: ".5rem 2rem",
              borderRadius: "1.75rem",
            }}
          >
            Envio exitoso
          </h3>
          <h3 className="cobra-hoy-frase" style={{ fontSize: "1.5rem" }}>
            Tu solicitud ha sido recibida. <br /> En breve un ejecutivo se comunicará contigo para ayudarte
          </h3>
          <h5 className="liquidez-inmediata-frase">Gracias por confiar en Genera!</h5>
        </div>
      </section>

      <section className="descripcion">
        <div className="como-funciona-box">
          <h6 className="palabra-como">
            Beneficios del Factoring de <span className="palabra-funciona"> Genera</span>{" "}
          </h6>
        </div>

        <div className="proceso">
          <div className="registro">
            <div>
              <img src={liquidezBanner} alt="registro" className="banner-proceso" />
            </div>

            <h5 className="registrate-palabra">Liquidez inmediata para tu negocio</h5>
            <p className="descripcion-procesos">
              Obtén dinero para pagar <br /> impuestos, proveedores, planilla o lo que tu negocio necesite..
            </p>
          </div>

          <div className="envio">
            <div>
              <img src={evaluacionBanner} alt="registro" className="banner-proceso" />
            </div>

            <h5 className="envio-palabra">Evaluamos a tu cliente, no a ti</h5>
            <p className="descripcion-procesos">
              La evaluación se centra en tu cliente, no en ti. Por ello puedes apoyarte en la solidez crediticia de tu
              comprador..
            </p>
          </div>

          <div className="desembolso">
            <div>
              <img src={reduccionBanner} alt="registro" className="banner-proceso" />
            </div>

            <h5 className="desembolso-palabra">Reduce esfuerzos de cobranza</h5>
            <p className="descripcion-procesos">
              Nosotros nos encargamos de la gestión de cobro llegada la fecha de vencimiento de la factura.
            </p>
          </div>

          <div className="desembolso">
            <div>
              <img src={financiamientoBanner} alt="registro" className="banner-proceso" />
            </div>

            <h5 className="desembolso-palabra">Financiamiento 100% digital</h5>
            <p className="descripcion-procesos">
              Olvídate de la pérdida de tiempo y trámites engorrosos, te ofrecemos un proceso rápido y digital.
            </p>
          </div>
        </div>
      </section>

      <section className="video" style={{ backgroundColor: "#003262" }}>
        <div className="video-container">
          <h5 className="video-text">Conoce el testimonio de otras personas:</h5>
          <div className="videos">
            <div className="video-cuadro">
              <YouTube videoId={videoId} opts={opts} className="react-youtube" />
            </div>
            <div className="video-cuadro">
              <YouTube videoId={videoId2} opts={opts} className="react-youtube" />
            </div>
          </div>
        </div>
      </section>

      <section
        className="banner"
        style={{
          height: "20rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="banner-registros"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <h6
            className="estamos-palabra"
            style={{
              color: "#003262",
              fontSize: "2rem",
              fontWeight: "500",
            }}
          >
            Estamos{" "}
            <span className="registrados-palabra" style={{ color: "#ff6300" }}>
              {" "}
              registrados en:
            </span>{" "}
          </h6>
          <div style={{ display: "flex", gap: "2rem" }}>
            <img src={superintendencia} alt="banner" className="superintendencia" style={{ width: "12rem" }} />
            <img src={produce} alt="banner" className="produce" style={{ width: "12rem" }} />
            <img src={cavali} alt="banner" className="cavali" style={{ width: "12rem" }} />
            <img src={apefac} alt="banner" className="apefac" style={{ width: "12rem" }} />
          </div>
        </div>
      </section>

      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-312618282"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-312618282');
          `}
        </script>

        <script>
          {`
              gtag('event', 'conversion', {
                  'send_to': 'AW-312618282/-lwdCPjNwu4CEKraiJUB',
                  'value': 20.0,
                  'currency': 'PEN'
              });
            `}
        </script>

      </Helmet>
    </div>
  );
}
