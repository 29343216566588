import React, { useEffect } from "react";
import "./factoring.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { Helmet } from "react-helmet";

function Gracias() {
  function scrollToSection() {
    let seccion = document.getElementById("formulario");
    seccion.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <script>
          {`
              gtag('event', 'conversion', {'send_to': 'AW-312618282/-lwdCPjNwu4CEKraiJUB'});
              `}
        </script>
      </Helmet>
      <header
        class="top-nav"
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "6rem" }}
      >
        <a href="https://generafactoring.pe/" class="logo-container">
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/genera-logo-interno.png"
            alt=""
            class="logo"
          />
        </a>
      </header>
      <section class="acelera-flujo" style={{ alignItems: "center" }}>
        <img
          src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%201-Aug-28-2023-04-47-45-1120-PM.png"
          alt="acelera-el-pago-de-tus-facturas"
          class="acelera-flujo-imagen"
        />
        <div class="acelera-flujo-informacion">
          <span class="acelera-flujo-informacion-encabezado">Envio exitoso</span>
          <h1 class="acelera-flujo-informacion-subencabezado" style={{ fontSize: "2.5rem", width: "40rem" }}>
            Tu solicitud ha sido recibida. <br /> En breve un ejecutivo se comunicará contigo para ayudarte
          </h1>
          <h2 class="acelera-flujo-informacion-parrafo-1" style={{ fontSize: "1.5rem" }}>
            Gracias por confiar en Genera!
          </h2>
        </div>
      </section>

      <section class="haz-factoring">
        <div class="haz-factoring-informacion">
          <h3 class="haz-factoring-encabezado">
            3 simples pasos para obtener <span>liquidez</span>
          </h3>
          <div class="haz-factoring-etapas">
            <div class="haz-factoring-etapa">
              <img
                src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/factura.png"
                alt="envianos-tus-facturas"
                class="haz-factoring-etapa-imagen"
              />
              <div>
                <h4 class="haz-factoring-etapa-encabezado">Envíanos tus facturas</h4>
                <p class="haz-factoring-etapa-parrafo">Evaluamos a tu cliente y te enviamos la propuesta</p>
              </div>
            </div>
            <div class="haz-factoring-etapa">
              <img
                src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/notificacion.png"
                alt="notificacion"
                class="haz-factoring-etapa-imagen"
              />
              <div>
                <h4 class="haz-factoring-etapa-encabezado">Notificación</h4>
                <p class="haz-factoring-etapa-parrafo">Le comunicamos a tu cliente la cesión de las facturas</p>
              </div>
            </div>
            <div class="haz-factoring-etapa">
              <img
                src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/cobro-1.png"
                alt="desembolso"
                class="haz-factoring-etapa-imagen"
              />
              <div>
                <h4 class="haz-factoring-etapa-encabezado">Desembolso</h4>
                <p class="haz-factoring-etapa-parrafo">Depositamos directamente el monto a tu cuenta corriente</p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2012-4.png"
          alt="haz-factoring"
          class="haz-factoring-imagen"
        />
      </section>

      <section class="asociados">
        <h4 class="asociados-encabezado">Estamos registrados en:</h4>
        <div class="asociados-imagenes">
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%209-1.png"
            alt="ministerio-de-la-produccion"
            class="asociados-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2010-2.png"
            alt="superintendencia-de-banca"
            class="asociados-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2011-2.png"
            alt="cavali"
            class="asociados-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2012-1.png"
            alt="apefac"
            class="asociados-imagen"
          />
        </div>
      </section>

      <section class="trabajamos">
        <h4 class="trabajamos-titulo">
          Trabajamos con <span>facturas de:</span>
        </h4>
        <div class="trabajamos-imagenes">
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%203-2.png"
            alt="backus"
            class="trabajamos-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%209@2x.png"
            alt="metro-de-lima"
            class="trabajamos-imagen imagen-cover"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2021@2x.png"
            alt="souther-copper"
            class="trabajamos-imagen imagen-cover"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2012@2x.png"
            alt="msd"
            class="trabajamos-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%207@2x-1.png"
            alt="enel"
            class="trabajamos-imagen"
          />

          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2015@2x.png"
            alt="rintisa"
            class="trabajamos-imagen imagen-cover"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2011@2x.png"
            alt="latina"
            class="trabajamos-imagen "
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2020@2x.png"
            alt="unacem"
            class="trabajamos-imagen"
          />

          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2019@2x.png"
            alt="universidad-del-pacifico"
            class="trabajamos-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2017@2x.png"
            alt="engie"
            class="trabajamos-imagen imagen-cover"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%208@2x-1.png"
            alt="yanbal"
            class="trabajamos-imagen"
          />

          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2016@2x.png"
            alt="mallplaza"
            class="trabajamos-imagen"
          />

          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2014@2x.png"
            alt="gloria"
            class="trabajamos-imagen"
          />

          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2018@2x-1.png"
            alt="lari"
            class="trabajamos-imagen"
          />
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2010@2x.png"
            alt="linea2"
            class="trabajamos-imagen"
          />
        </div>
      </section>

      <section class="videos">
        <h4 class="videos-titulo">Testimonios</h4>

        <Swiper navigation={true} class="swiper" modules={[Navigation]} loop={true}>
          <SwiperSlide class="swiper-slide">
            <embed
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wgHaZ5YoJZY"
              frameborder="0"
              allowfullscreen
              class="swiper-video video-iframe"
            />
          </SwiperSlide>
          <SwiperSlide class="swiper-slide">
            <embed
              width="560"
              height="315"
              src="https://www.youtube.com/embed/mD39TDeXMKA"
              frameborder="0"
              allowfullscreen
              class="swiper-video video-iframe"
            />
          </SwiperSlide>
          <SwiperSlide class="swiper-slide">
            <embed
              width="560"
              height="315"
              src="https://www.youtube.com/embed/5ztU9fcNfpg"
              frameborder="0"
              allowfullscreen
              class="swiper-video video-iframe"
            />
          </SwiperSlide>
          {/* <div class="swiper-pagination"></div>
          <div class="boton-prev">
            <div class="swiper-button-prev"></div>
          </div>
          <div class="swiper-button-next"></div> */}
        </Swiper>
      </section>

      <footer class="footer">
        <div class="footer-principal">
          <a href="https://generafactoring.pe/" class="logo-container">
            <img
              src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/genera-logo-interno.png"
              alt=""
              class="footer-logo"
            />
          </a>
          <p class="footer-principal-parrafo">
            Somos tu alternativa de financiamiento, adelantamos el pago de tus facturas a crédito.
          </p>
          <div class="contacto-container">
            <a href="mailto:comercial@grupogenera.pe" class="contact-imagen" target="_blank">
              <i class="fa-solid fa-envelope fa-2xl footer-imagen" alt="correo"></i>
              <span></span>comercial@grupogenera.pe
            </a>
          </div>
          <div class="contacto-container">
            <a href="tel:+014851170" class="contact-imagen" target="_blank">
              <i class="fa-solid fa-phone fa-2xl footer-imagen" alt="numero"></i>
              <span></span>(01) 485-1170
            </a>
          </div>
          <div class="footer-redes">
            <a href="https://www.instagram.com/genera.factoring/" target="_blank">
              <i class="fa-brands fa-instagram fa-2xl footer-imagen" alt="instagram"></i>
            </a>

            <a href="https://www.youtube.com/channel/UCYyfQWRmk1KFlS92KV-7Kng/featured" target="_blank">
              <i class="fa-brands fa-youtube fa-2xl footer-imagen" alt="youtube"></i>
            </a>

            <a href="https://www.linkedin.com/company/grupo-genera-pe?originalSubdomain=pe" target="_blank">
              <i class="fa-brands fa-linkedin fa-2xl footer-imagen" alt="linkedin"></i>
            </a>

            <a href="https://www.facebook.com/grupogenera/" target="_blank">
              <i class="fa-brands fa-facebook fa-2xl footer-imagen" alt="facebook"></i>
            </a>

            <a href="https://www.tiktok.com/@genera.factoring" target="_blank">
              <i class="fa-brands fa-tiktok fa-2xl footer-imagen" alt="tiktok"></i>
            </a>

            <a href="https://wa.me/+51907243817" target="_blank">
              <i class="fa-brands fa-whatsapp fa-2xl footer-imagen" alt="whatsapp"></i>
            </a>
          </div>
        </div>

        <div class="footer-secciones">
          <div class="footer-servicios">
            <h4 class="footer-servicios-titulo">Servicios</h4>
            <div class="links">
              <a href="https://generafactoring.pe/factoring">Factoring</a>
              <a href="https://generafactoring.pe/inversion-factoring">Inversión</a>
              <a href="https://generafactoring.pe/cobranza-libre">Cobranza Libre</a>
            </div>
          </div>
          <div class="footer-servicios">
            <h4 class="footer-servicios-titulo">Conoce más</h4>
            <div class="links">
              <a href="https://generafactoring.pe/quienes-somos">¿Quiénes somos?</a>
              <a href="https://blog.generafactoring.pe/">Blog</a>
              <a href="https://generafactoring.pe/preguntas-frecuentes">Preguntas Frecuentes</a>
            </div>
          </div>
          <div class="footer-servicios">
            <h4 class="footer-servicios-titulo">Transparencia</h4>
            <div class="links">
              <a href="https://generafactoring.pe/politicas">Políticas de privacidad</a>
              <a href="https://blog.grupogenera.pe/es/mejora-tu-flujo-de-efectivo-con-factoring">
                Términos y Condiciones
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Gracias;
