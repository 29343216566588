import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./formulario.css";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Search, FileText } from "react-feather";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function FormularioFactoring() {
  const [factura, setFactura] = useState("");
  const [reporteTributario, setReporteTributario] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [moneda, setMoneda] = useState("Soles");
  const [siguienteTexto, setSiguienteTexto] = useState("Siguiente");
  const navigate = useNavigate()
  const [cookies, setCookies] = useState("");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      // vendes_a_credito: "",
      ruc_cliente: "",
      // nombre: "",
      // celular: "",
      // email: "",
      haz_utilizado: "",
      cuentas_con_facturas: "",
      ruc_deudor: "",
      moneda: "Soles",
      monto_solicitar: "1500",
      // apellidos: ""
    },
  });

  const valorMoneda = watch("moneda");

  const validateFile = (value, required) => {
    if (!value && !required) {
      return true;
    }

    if (!value) {
      return true;
    }

    const allowedExtensions = [".pdf", ".zip"];
    const fileName = value?.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      return "El archivo deber ser PDF o ZIP";
    }
  };

  function onChange(e) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
    }
  }

  const validacionRuc20o10 = (value) => {
    if (value?.startsWith("20") === false && value?.startsWith("10") === false) {
      return "El ruc debe iniciar con los digitos 20 o 10";
    } else {
      return true;
    }
  };

  const validacionRuc20 = (value) => {
    if (value?.startsWith("20") === false) {
      return "El ruc debe iniciar con los digitos 20";
    } else {
      return true;
    }
  };

  const validacionMoneda = (value) => {
    if (value < 1500 && valorMoneda === "Soles") {
      return "El monto debe ser mayor a S/. 1500";
    } else if (value < 500 && valorMoneda === "Dolares") {
      return "El monto debe ser mayor a $. 500";
    } else {
      return true;
    }
  };

  useEffect(() => {
    trigger("monto_solicitar");
  }, [moneda]);

  useEffect(() => {
    let cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].split("=");
      if (cookie[0] === "hubspotutk") {
        setCookies(cookie[1]);
        console.log(cookies)
      }
    }
  }, []);

  function preventArrowKeys(event) {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  }

  async function EnviarFormulario(data) {
    await axios
      .post(`https://notify.grupogenera.pe/api/enviar/${cookies}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => navigate("/gracias"));
  }

  async function PrimerFiltro(clienteReporte, deudorReporte, sunatCliente, sunatDeudor, vendesACredito, data) {
    if (
      sunatCliente == "si existe" &&
      clienteReporte == "empresa valida" &&
      sunatDeudor == "si existe" &&
      deudorReporte == "empresa valida" &&
      vendesACredito == "Si"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
    }

    if (vendesACredito != "Si") {
      Swal.fire({
        html: `<p>
        Gracias por confiar en Genera Factoring, te comentamos que el Factoring es una alternativa de financiamiento
        para las MYPES, para poder utilizarlo es necesario vender al credito a tus clientes y contar con facturas por
        cobrar a 30, 60, 90 o hasta 120 días. Te invitamos a visitar este enlace para conocer más sobre el Factoring y
        como funciona. <br />
        <a href="https://bit.ly/GGFactoring" target="_blank" style={{ textDecoration: "none", fontWeight: "700" }}>
          https://bit.ly/GGFactoring
        </a>
        </p>`,
        position: "center",
        confirmButtonText: "Cerrar",
      });
    } else if (sunatCliente == "ruc no valido" || sunatCliente == "not found") {
      Swal.fire({
        text: "EL RUC de tu empresa no es válido, por favor volver a ingresar.",
        position: "center",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#FF6C00",
      });
      return;
    } else if (sunatDeudor == "ruc no valido" || sunatDeudor == "not found") {
      Swal.fire({
        text: "EL RUC de tu cliente ingresado no es válido, por favor volver a ingresar.",
        position: "center",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#FF6C00",
      });
      return;
    } else if (deudorReporte == "empresa no valida" && clienteReporte == "empresa valida") {
      await axios
        .post(`https://notify.grupogenera.pe/api/crear-contacto-rechazado`, data)
        .then((res) => console.log(res.data));
      Swal.fire({
        html: `<span>
        Datos recibidos exitosamente.
        <br />
        Gracias por confiar en Genera, su cliente no aprueba la evaluación para realizar factoring, 
        pero si tienes otro cliente que desee evaluar puede dar clic en Volver a evaluar.
        </span>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Volver a evaluar",
        confirmButtonColor: "#FF6C00",
      }).then((result) => {
        if (result.isConfirmed) {
          reset({
            vendes_a_credito: data.vendes_a_credito,
            ruc_cliente: data.ruc_cliente,
            nombre: data.nombre,
            celular: data.celular,
            email: data.email,
            haz_utilizado: "",
            cuentas_con_facturas: "",
            ruc_deudor: "",
            moneda: "Soles",
            monto_solicitar: "1500",
            apellidos: data.apellidos,
          });
          return;
        }
      });
    }
  }

  function isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async function validacionRucClienteDeudor(ruc_cliente, ruc_deudor, vendes_a_credito, data) {
    await axios
      .get(`https://notify.grupogenera.pe/api/buscar-ruc-reporte-negativo/${ruc_cliente}/${ruc_deudor}`)
      .then((res) => {
        PrimerFiltro(
          res.data?.reporte_cliente,
          res.data?.reporte_deudor,
          res.data?.sunat_cliente,
          res.data?.sunat_deudor,
          vendes_a_credito,
          data
        );
      });
  }

  async function Submit(data, e) {
    console.log(data);
    e.preventDefault();
    if (activeStep === 0) {
      setSiguienteTexto("Revisando...");
      await validacionRucClienteDeudor(data.ruc_cliente, data.ruc_deudor, data.vendes_a_credito, data);
      setSiguienteTexto("Siguiente");
    }

    if (activeStep === 1) {
      await EnviarFormulario(data);
      setFactura(Date.now().toString());
      setReporteTributario(Date.now().toString());
      reset({
        vendes_a_credito: "",
        ruc_cliente: "",
        nombre: "",
        celular: "",
        email: "",
        haz_utilizado: "",
        cuentas_con_facturas: "",
        ruc_deudor: "",
        moneda: "Soles",
        monto_solicitar: "1500",
        apellidos: "",
      });
      setActiveStep(0);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    reset();
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className="formulario-seccion" id="formulario">
      {/* <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            padding: "2rem",
          }}
        >
          <img
            src="https://9216277.fs1.hubspotusercontent-na1.net/hubfs/9216277/Recurso%2020@2x-1.png"
            alt=""
            style={{ width: "4rem" }}
          />
          <p className="envio-exitoso">¡ENVIO EXITOSO!</p>
          <p className="solicitud-enviada">
            Gracias por confiar en Genera Factoring, esta solo ha un paso de continuar con su operación: Si deseas
            acelerar tu atención, procura tener disponible la siguiente información: <br /> ► Tu factura en formato PDF{" "}
            <br /> ► El xml de tu factura en formato ZIP <br /> ► Guía de remisión o Acta de conformidad
          </p>
        </ModalBody>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-312618282"></script>
          <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-312618282');
            `}
          </script>

          <script>
            {`
                gtag('event', 'conversion', {
                    'send_to': 'AW-312618282/-lwdCPjNwu4CEKraiJUB',
                    'value': 20.0,
                    'currency': 'PEN'
                });
              `}
          </script>

          <script>
            {`
              function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-312618282/-aOLCNrBw4cZEKraiJUB',
                  'event_callback': callback
              });
              return false;
              }`}
          </script>
        </Helmet>
      </Modal> */}
      <div
        style={{
          backgroundColor: "#134C90",
          borderRadius: "1rem",
        }}
        className="formulario-box"
      >
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>
                {" "}
                <Search /> Pre-Calificación
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {" "}
                <FileText /> Registra tus datos
              </StepLabel>
            </Step>
            {/* {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })} */}
          </Stepper>
          <React.Fragment>
            <form onKeyDown={checkKeyDown}>
              {activeStep === 0 ? (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <Row xs="30">
                    {activeStep === 0 && (
                      <FormGroup>
                        <Controller
                          name="vendes_a_credito"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Seleccionar Si o No",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              type="select"
                              bsSize="xl"
                              {...field}
                              className="input"
                              placeholder="¿Vendes a crédito a tus clientes?"
                              style={{ paddingLeft: "1rem" }}
                            >
                              <option value="">¿Vendes a crédito a tus clientes?</option>
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </Input>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="vendes_a_credito"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    )}
                  </Row>

                  <Row xs="18">
                    <Col>
                      <FormGroup>
                        <Controller
                          name="nombre"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Ingresar nombre",
                            },
                          }}
                          render={({ field }) => (
                            <Input className="input" type="text" placeholder="Nombres" {...field} />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="nombre"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Controller
                          name="apellidos"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Ingresar apellidos",
                            },
                          }}
                          render={({ field }) => (
                            <Input className="input" type="text" placeholder="Apellidos" {...field} />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="apellidos"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs="18">
                    <FormGroup>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Ingresar correo electrónico",
                          },
                          validate: {
                            matchPattern: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "El correo no es valido",
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            type="text"
                            placeholder="Ingresa tu correo electrónico*"
                            {...field}
                            formNoValidate={true}
                            className="input"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => <p className="mensaje-error">{message}</p>}
                      />
                    </FormGroup>
                  </Row>

                  <Row xs="18">
                    <Col xs="2">
                      <Input type="text" className="input-prefijo" value="+51" disabled />
                    </Col>

                    <Col>
                      <FormGroup>
                        <Controller
                          name="celular"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Ingresar número de celular",
                            },
                            minLength: {
                              value: 9,
                              message: "Debe ser 9 digitos",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              type="number"
                              placeholder="N° de celular*"
                              {...field}
                              maxLength="9"
                              className="input"
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="celular"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs="18">
                    <FormGroup>
                      <Controller
                        name="ruc_cliente"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Ingresar ruc de tu empresa",
                          },
                          minLength: {
                            value: 11,
                            message: "Faltan digitos por llenar",
                          },
                          validate: {
                            validacionRuc20o10,
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            type="number"
                            placeholder="Ingresa tu RUC"
                            maxLength={11}
                            {...field}
                            disabled={activeStep > 0 ? true : false}
                            className="input"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="ruc_cliente"
                        render={({ message }) => <p className="mensaje-error">{message}</p>}
                      />
                    </FormGroup>
                  </Row>

                  <Row xs="18">
                    <FormGroup>
                      <Controller
                        name="ruc_deudor"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Ingresar ruc de tu cliente",
                          },
                          minLength: {
                            value: 11,
                            message: "Faltan digitos por llenar",
                          },
                          validate: {
                            validacionRuc20,
                          },
                        }}
                        render={({ field: { onBlur, ...field } }) => (
                          <Input
                            type="number"
                            bsSize="xl"
                            {...field}
                            maxLength={11}
                            placeholder="Ingrese el RUC de tu cliente*"
                            className="input"
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="ruc_deudor"
                        render={({ message }) => <p className="mensaje-error">{message}</p>}
                      />
                    </FormGroup>
                  </Row>

                  <p style={{ color: "white", padding: 0, margin: 0, fontSize: ".8rem" }}>*Campos Obligatorios</p>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, justifyContent: "center" }}>
                    <Button type="submit" className="boton-enviar" onClick={(e) => handleSubmit(Submit)(e)}>
                      {activeStep === 1 ? "Enviar" : siguienteTexto}
                    </Button>
                  </Box>
                  <Toaster
                    toastOptions={{
                      className: "",
                      style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                        fontSize: window.innerWidth < 600 ? ".75rem" : "1rem",
                      },
                    }}
                  />
                </Typography>
              ) : activeStep === 1 ? (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <br />
                  <Row xs="18">
                    <FormGroup>
                      <Controller
                        name="haz_utilizado"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Ingresar Si o No",
                          },
                        }}
                        render={({ field }) => (
                          <Input type="select" bsSize="xl" {...field} className="input">
                            <option value="">¿Haz utilizado el factoring?*</option>
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </Input>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="haz_utilizado"
                        render={({ message }) => <p className="mensaje-error">{message}</p>}
                      />
                    </FormGroup>
                  </Row>

                  <Row xs="18">
                    <FormGroup>
                      <Controller
                        name="cuentas_con_facturas"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Seleccione una de las alternativas",
                          },
                        }}
                        render={({ field }) => (
                          <Input type="select" bsSize="xl" {...field} className="input">
                            <option value="">¿Tienes facturas por cobrar?*</option>
                            <option value="Vigentes">Vigentes</option>
                            <option value="Vencidas">Vencidas</option>
                            <option value="Por Facturar">Por facturar</option>
                            <option value="Ninguna">Ninguna</option>
                          </Input>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="cuentas_con_facturas"
                        render={({ message }) => <p className="mensaje-error">{message}</p>}
                      />
                    </FormGroup>
                  </Row>

                  <Row xs="18">
                    <Col>
                      <FormGroup>
                        <Label style={{ color: "white" }}>Moneda</Label>
                        <Controller
                          name="moneda"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Input
                              type="select"
                              bsSize="xl"
                              {...field}
                              onChange={(e) => {
                                onChange(e.target.value);
                                setMoneda(e.target.value);
                              }}
                              className="input"
                            >
                              <option value="">Seleccione</option>
                              <option value="Soles">Soles</option>
                              <option value="Dolares">Dolares</option>
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label style={{ color: "white" }}>Monto a solicitar</Label>
                        <Controller
                          name="monto_solicitar"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Ingrese monto a solicitar",
                            },
                            validate: { validacionMoneda },
                          }}
                          render={({ field }) => (
                            <Input
                              type="number"
                              bsSize="xl"
                              {...field}
                              className="no-arrows input"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={preventArrowKeys}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="monto_solicitar"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ color: "white" }}>Sube tu factura(opcional)</Label>
                        <Controller
                          name="factura"
                          control={control}
                          rules={{
                            required: false,
                            validate: {
                              validateFile,
                            },
                          }}
                          key={factura}
                          render={({ field: { value, onChange, ...field } }) => (
                            <Input
                              type="file"
                              bsSize="xl"
                              {...field}
                              onChange={(event) => {
                                onChange(...event.target.files);
                              }}
                              className="input"
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="factura"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ color: "white" }}>Reporte Tributario(opcional)</Label>
                        <Controller
                          name="reporte_tributario"
                          control={control}
                          rules={{
                            validate: {
                              validateFile,
                            },
                            required: false,
                          }}
                          key={reporteTributario}
                          render={({ field: { value, onChange, ...field } }) => (
                            <Input
                              type="file"
                              bsSize="xl"
                              {...field}
                              onChange={(event) => {
                                onChange(...event.target.files);
                              }}
                              className="input"
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="reporte_tributario"
                          render={({ message }) => <p className="mensaje-error">{message}</p>}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      className="boton-regresar"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Regresar
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type="submit" className="boton-enviar" onClick={(e) => handleSubmit(Submit)(e)}>
                      {activeStep === 1 ? "Enviar" : "Siguiente"}
                    </Button>
                  </Box>
                  <Toaster />
                </Typography>
              ) : null}
            </form>
          </React.Fragment>
        </Box>
      </div>
    </div>
  );
}
