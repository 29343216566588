import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import GraciasPagina from "./GraciasPagina/GraciasPagina";
import Home from "./Home/Home";
import "./App.css";
import Factoring from "./Factoring/Factoring";
import Gracias from "./Factoring/Gracias";

function App() {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   function handleResize() {
  //     const windowWidth = window.innerWidth;
  //   }

  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      {/* <header className="header">
            <img src={logoGenera} alt="logo" className="logo" />
          </header> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/factoring" element={<Factoring />} />

          <Route path="/gracias-factoring" element={<GraciasPagina />} />
          <Route path="/gracias" element={<Gracias />} />
          <Route path="/:hk" element={<Home />} />

        </Routes> 

      {/* 
          <section className="footer">
            <div
              className="video-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <img src={generaBanner} alt="banner" className="genera-banner" />
              <h5 className="palabra-creces">¡Creces tú, crecemos todos!</h5>
              <div style={{ display: "flex", gap: "2rem" }}>
                <a
                  href="https://www.facebook.com/grupogenera"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <FaFacebookF size={playerWidth < 600 ? 18 : 30} className="social-icon" />
                </a>

                <a
                  href="https://www.instagram.com/genera.factoring/"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <FaInstagram size={playerWidth < 600 ? 18 : 30} className="social-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/grupo-genera-pe/mycompany/"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <FaLinkedin size={playerWidth < 600 ? 18 : 30} className="social-icon" />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCYyfQWRmk1KFlS92KV-7Kng/videos"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <FaYoutube size={playerWidth < 600 ? 18 : 30} className="social-icon" />
                </a>
              </div>
            </div>
          </section> */}
    </div>
  );
}

export default App;
